module.exports = [{
      plugin: require('../node_modules/gatsby-remark-prismjs-copy-button/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/simarmannsingh/personal_m2_backup/Dev/BLOGS/modern-personal-blog/src/cms/cms.js"},
    }]
